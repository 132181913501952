/* Reset */
.nav,
.nav a,
.nav ul,
.nav li,
.nav div,
.nav form,
.nav input {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
.nav a {
  text-decoration: none;
}
.nav li {
  list-style: none;
}

/* Menu Container */
.nav {
  display: inline-block;
  position: relative;
  cursor: default;
  z-index: 500;
}

/* Menu List */
.nav > li {
  display: block;
  float: left;
}

/* Menu Links */
.nav > li > a {
  position: relative;
  display: block;
  z-index: 510;
  height: 35px;
  padding: 0 15px;
  line-height: 35px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav > li:hover > a {
  background: #eeeeee;
}

.nav > li:first-child > a {
  border-radius: 3px 0 0 3px;
  border-left: none;
}

/* Search Form */
.nav > li.nav-search > form {
  position: relative;
  width: inherit;
  height: 54px;
  z-index: 510;
  border-left: 1px solid #4b4441;
}

.nav > li.nav-search input[type='text'] {
  display: block;
  float: left;
  width: 1px;
  height: 24px;
  padding: 15px 0;
  line-height: 24px;

  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #999999;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.35);

  background: #372f2b;

  -webkit-transition: all 0.3s ease 1s;
  -moz-transition: all 0.3s ease 1s;
  -o-transition: all 0.3s ease 1s;
  -ms-transition: all 0.3s ease 1s;
  transition: all 0.3s ease 1s;
}

.nav > li.nav-search input[type='text']:focus {
  color: #fcfcfc;
}
.nav > li.nav-search input[type='text']:focus,
.nav > li.nav-search:hover input[type='text'] {
  width: 110px;
  padding: 15px 20px;

  -webkit-transition: all 0.3s ease 0.1s;
  -moz-transition: all 0.3s ease 0.1s;
  -o-transition: all 0.3s ease 0.1s;
  -ms-transition: all 0.3s ease 0.1s;
  transition: all 0.3s ease 0.1s;
}
.nav > li.nav-search input[type='submit'] {
  display: block;
  float: left;
  width: 20px;
  height: 54px;
  padding: 0 25px;
  cursor: pointer;
  background: #372f2b no-repeat center center;
  border-radius: 0 3px 3px 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nav > li.nav-search input[type='submit']:hover {
  background-color: #4b4441;
}

/* Menu Dropdown */
.nav > li > div {
  position: absolute;
  display: block;
  width: 100%;
  top: 35px;
  left: 0;

  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  -webkit-transition: all 0.05s ease 0.05s;
  -moz-transition: all 0.05s ease 0.05s;
  -o-transition: all 0.05s ease 0.05s;
  -ms-transition: all 0.05s ease 0.05s;
  transition: all 0.05s ease 0.05s;
}

.nav div .inner {
  position: absolute;
  display: block;
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 0 7px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

.nav > li:hover > div {
  opacity: 1;
  visibility: visible;
  overflow: visible;
}

/* Menu Content Styles */
.nav .nav-column {
  float: left;
  width: 90%;
  padding: 1% 3% 1% 3%;
  text-align: left;
}

.nav .nav-column h3 {
  margin: 20px 0 10px 0;
  line-height: 18px;

  font-size: 14px;
  color: #000;
  text-transform: uppercase;
}

.nav .nav-column h3.orange {
  color: #ff722b;
}

.nav .nav-column li a {
  display: inline-block;
  float: left;
  line-height: 26px;
  color: #69bb0b;
  text-transform: none;
  background: #eeeeee;
  margin: 5px 5px;
  padding: 5px 20px;
  border-radius: 3px;
}

.nav .nav-column li a:hover {
  color: black;
}

@media (max-width: 1200px) {
  .nav {
    margin: 20px 10px;
  }
  .nav > li {
    display: block;
    float: none;
  }

  .nav > li:hover > a {
    background: none;
    color: #ffffff;
  }

  .nav > li > a {
    position: relative;
    display: block;
    z-index: 510;
    height: auto;
    padding: 8px 15px;
    line-height: 120%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #a1cc50;
  }
  .nav > li > div {
    position: relative;
    display: block;
    width: 100%;
    top: auto;
    left: auto;

    opacity: 1;
    visibility: visible;
    overflow: auto;

    -webkit-transition: all 0.05s ease 0.05s;
    -moz-transition: all 0.05s ease 0.05s;
    -o-transition: all 0.05s ease 0.05s;
    -ms-transition: all 0.05s ease 0.05s;
    transition: all 0.05s ease 0.05s;
  }

  .nav > li:hover > div {
    opacity: 1;
    visibility: visible;
    overflow: auto;
  }

  .nav div .inner {
    position: relative;
    display: block;
    width: 100%;
    background: none;
  }

  .nav .nav-column li a {
    display: block;
    line-height: 15px;
    color: #a1cc50;
    text-transform: none;
    background: none;
    margin: 8px 0 10px 0;
    padding: 2px 9px 0 9px;
    border-radius: 3px;
    border-left: 13px solid #a1cc50;
    border-radius: 0;
  }
  .nav .nav-column li a:hover {
    color: white;
    border-left: 13px solid white;
  }
}
