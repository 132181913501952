.header-outer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
  background: white;
  border-bottom: 1px solid #eee;
  box-shadow: 8px 0 10px rgba(0, 0, 0, 0.2);
}

.header {
  margin: 0 auto;
  max-width: 1500px;
  left: 0;
  right: 0;
  top: 0;
  background: #ffffff;
  clear: both;
  padding: 35px 15px 15px 15px;
  position: relative;
  display: flex;
  align-items: center;
  transition: padding 300ms;
}

.header.smallHeader {
  padding: 7px 15px 4px 15px;
}

.header-under {
  position: relative;
  padding-bottom: 160px;
  width: 100%;
}
.book-button-link-header {
  position: absolute;
  top: 0;
  right: 10%;
  cursor: pointer;
  background: #8ac421;
  padding: 6px 25px 4px 25px;
  font-size: 18px;
  text-decoration: none;
  border-bottom: 3px solid #648f16;
  z-index: 50;
  border-radius: 0 0 5px 5px;
  color: white;
}
.book-button-link-header:hover {
  background: #7aae1b;
}

.main-logo {
  float: left;
  text-align: center;
  width: 200px;
}

.main-logo-img {
  max-width: 200px;
  transition: max-width 300ms;
}

.main-logo-img.smallLogo {
  max-width: 140px;
}

.links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 80%;
}

@media (max-width: 1200px) {
  .main-logo {
    margin-left: 20px;
  }
}

@media (max-width: 1000px) {
  .main-logo-img {
    max-width: 150px;
  }
  .header-under {
    position: relative;
    padding-bottom: 120px;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .book-button-link-header {
    right: 25%;
  }
}

@media (max-width: 700px) {
  .main-logo-img {
    max-width: 130px;
  }
}
