.hero-container {
  position: relative;
  max-width: 1700px;
  margin: 0 auto;
  overflow: hidden;
}

.hero-container-main-image {
  position: relative;
  display: table-cell;
  max-height: 650px;
}
.hero-container-border-bottom {
  position: absolute;
  bottom: 0;
}
.hero-container-border-bottom img {
  width: 100%;
  margin-bottom: -1px;
}
.hero-image {
  background-image:url("/media/homepage/hero-image-full-width.jpg");
  background-position: center center;
  background-size: cover;
}

.hero-text-container-wrapper {
  position: relative;
  box-sizing:border-box;
  width: 55%;
  margin-left:45%;
  bottom:0;
  text-align: center;
  background:rgba(0,0,0,.65);
  border-left:8px solid rgba(255,255,255,1);
}

.hero-text-container {
  padding:40px 40px 10px 40px;
  font-size: 20px;
  line-height:132%;
  color: white;
  text-align:left;
  max-width:790px;
  margin: 0 auto;
}

.hero-text-container h1 {
  font-size: 60px;
  line-height: 65px;
  padding-top: 10px;
  color:white;

}
.hero-badge-container {
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  padding: 0 15px 20px 15px;
  max-width:600px;
  margin:0 auto;
}
.badge {
  margin:5px 15px;
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #9ccc42;
  border: 5px solid white;
  border-radius: 80px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  color: white;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  display: table;
  -webkit-transition: background-color 0.2s linear, box-shadow 0.2s linear,
    bottom 0.2s;
  transition: background-color 0.2s linear, box-shadow 0.2s linear, bottom 0.2s;
}

.badge:hover {
  background-color: rgb(7, 161, 7);
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.3);
  bottom: 4px;
}

.badge-active {
  cursor: pointer;
}

.badge-left {
  
}

.badge-mid {
  
}

.badge-right {
  
}

.badge-text {
  display: table-cell;
  align: center;
  vertical-align: middle;
  font-size: 25px;
  line-height: 34px;
  color: white;
  text-decoration: none;
}

@media (max-width:1200px) {
  .hero-text-container-wrapper {
    width:100%;
    margin:0;
  }
  
}

@media(max-width:550px) {
  .hero-text-container h1 {
    font-size:10vw;
    line-height:130%;
  }
  .hero-image {
    background-image:none;
    background-position: center center;
    background-size: cover;
  }
}

