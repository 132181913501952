.site-links {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  text-align: right;
}

.header-links-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.link-button {
  display: inline-block;
  padding: 7px 10px 3px 10px;
  margin-left: 5px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}

.link-active {
  cursor: pointer;
  -webkit-transition: background-color 0.2s linear, color 0.2s linear;
  transition: background-color 0.2s linear, color 0.2s linear;
}

.link-active:hover {
  background-color: #eee;
  border-radius: 3px;
  color: #000000;
}

.link-inactive:hover {
  color: #000000;
}

.link-dark {
  color: #777777;
}

.link-green {
  color: #9ccc42;
  color: #69bb0b;
}

.mobile-menu-button {
  display: none;
}
@media (max-width: 1200px) {
  .mobile-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: -62px;
    width: 60px;
    height: 60px;
    background: #9ccc42;
    border-radius: 0 0 6px 6px;
    cursor: pointer;
    z-index: 11;
  }
  .mobile-menu-button .line {
    position: relative;
    margin: 3px auto;
    background: white;
    height: 8px;
    width: 40px;
    border-radius: 4px;
  }
  .site-links {
    position: fixed;
    width: 265px;
    max-width: 265px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    text-align: left;
    background: #555;
    z-index: 10;
    border-left: 2px solid #ffffff;
    transition: margin 0.5s;
  }
  .links-outer {
    position: relative;
    overflow-y: scroll;
    height: 100%;
  }
  .link-green {
    color: #333333;
  }
  .link-dark {
    color: #333333;
  }
  .site-links-out {
    margin-right: -267px;
  }
  .site-links-in {
    margin-right: 0px;
  }
  .header-links-ul {
    display: block;
    flex-wrap: wrap;
    justify-content: center;
  }
}
