@font-face {
  font-family: Pim;
  font-weight: regular;
  src: url("/media/fonts/pim-regular.otf") format("opentype");
}
@font-face {
  font-family: Pim;
  font-weight: bold;
  src: url("/media/fonts/pim-bold.otf") format("opentype");
}
@font-face {
  font-family: Pim;
  font-style: italic;
  src: url("/media/fonts/pim-italic.otf") format("opentype");
}
@font-face {
  font-family: Handy;
  font-style: regular;
  src: url("/media/fonts/handy.ttf") format("opentype");
}



body {
  margin: 0;
  padding: 0;
  font-family: "Pim", "Hind Guntur", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size:17px;
  line-height:140%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight:normal;
  margin:0;
  padding:0;
  line-height:140%;
  font-size:40px;
}

