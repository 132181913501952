.home-container-mid {
    position:relative;
    width:80%;
    margin:50px auto;
    color:#ffffff;
    display:block;
}
.home-container-mid .left {
    float:left;
    width:50%;
    overflow:hidden;
    max-height:400px;
}
.home-container-mid .left img {
    max-width:550px;
    margin:0;
    padding:0;
}
.home-container-mid .right {
    width:50%;
    
    float:right;
}
.home-container-mid .right .inner {
    padding:70px 50px;
}
@media (max-width:800px) {
    .home-container-mid {
        width:100%;
    }
}

.badge-box {
    display:table; 
    align-content: center;
    margin:0 auto;
}
.badge-box-inner {
    display: table-cell;
   
}
.badge-box-divider {
    width:60px; 
    height:0; 
    border-top:2px solid #ccc;
    margin:75px 10px 0 10px;
}
.width-height-absolute {
    width:150px;
    height:150px;
    position:relative;
}


@media (max-width:600px) {
    .badge-box-divider {
        border:none;
        margin:10px;
        width:auto;
    }
    .width-height-absolute {
        width:100px;
        height:100px;
        position:relative;
    }
}
@media (max-width:500px) {
    .width-height-absolute {
        width:90px;
        height:90px;
        position:relative;
    }
}

.tent-text-container {
    position:absolute;
    bottom:2%;
    width:100%;
    padding:0 15px;
    box-sizing:border-box;
}
.tent-text-container .large {
    position:relative;
    font-size:100px;
    line-height:60px;
    font-weight:bold;
    color:#9ccc42;
    -webkit-text-fill-color:white;
    text-align:center;
    -webkit-text-stroke-width: 2px;
    padding-bottom:40px;
}
.tent-text-container .small {
    position:relative;
    font-size:47px;
    line-height:50px;
    font-weight:bold;
    color:white;
    text-align:center;
}
.green-overlay-text-container {
    position:absolute;
    top:80px;
    left:0;
    right:0;
}

.flex-three-column {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: stretch;
}
.flex-two-column {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: stretch;
}
.flex-column-half {
    width:50%;
}
.flex-column-half .inner {
    margin:25px;
}
.flex-column-third {
    width: 30%; 
}
.flex-column-third .inner {
    margin:25px;
}

.man-symbol {
    width:9%;
    padding-right:1%;
}

@media (max-width:960px) {
    .flex-three-column {
        flex-direction:column;
        align-items:center;
    }
    .flex-column-third {
        width: 80%; 
        margin:8px 0;
    }
    .man-symbol {
        width:4%;
        padding-right:1%;
    }
}

@media (max-width:700px) {
    .flex-three-column {
        flex-direction:column;
        align-items:center;
    }
    .flex-two-column {
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        align-items: center;
    }
    .flex-column-half {
        margin:12px 0;
        width:95%;
    }
    .flex-column-third {
        width: 95%; 
        margin:8px 0;
    }
    .man-symbol {
        width:4%;
        padding-right:1%;
    }
}

.speech-bubble {
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    border: 5px solid #fff;
    font-size:23px;
    line-height:35px;
    border-radius:50%;
    width:330px;
    height:330px;
    box-shadow: 3px 3px 8px rgba(0,0,0,.5);
}
.speech-bubble-blue-back {
    background:rgba(0,146,159,.7);
}
.speech-bubble-light-blue-back {
    background:rgba(116,201,186,.7);
}
.speech-bubble-green-back {
    background:rgba(158,204,59,.7);
}
.speech-bubble-break {
    display:inline-block;
    line-height:0;
}

@media (max-width:1200px) {
    .speech-bubble {
        display:flex;
        align-items:center;
        justify-content:center;
        border: 5px solid #fff;
        font-size:20px;
        line-height:32px;
        border-radius:50%;
        width:260px;
        height:260px;
        box-shadow: 3px 3px 8px rgba(0,0,0,.5);
    }
}
@media (max-width:960px) {
    .green-overlay-text-container {
        position:relative;
        top:0;
        background:rgba(158,204,59,1);
        padding:15px;
    }
    .speech-bubble {
        display:flex;
        align-items:center;
        justify-content:center;
        border: 5px solid #fff;
        font-size:20px;
        line-height:32px;
        border-radius:0;
        border:1px solid #ffffff;
        width:80%;
        height:auto;
        padding:15px;
        box-shadow: 5px 5px 10px rgba(0,0,0,.3);
        margin: 15px auto;
    }
    .speech-bubble-break {
        display:none;
    }
}

.thoughtful-caption {
    position:absolute;
    left:10%;
    bottom:100px;
    width:40%;
    font-size:50px;
    line-height:150%;
    color:#ffffff;
}

@media (max-width:1200px) {
    .thoughtful-caption {
        width:50%;
        font-size:40px;
        bottom:60px;
    }    
}

@media (max-width:960px) {
    .thoughtful-caption {
        width:50%;
        font-size:35px;
        bottom:20px;
    }    
}

@media (max-width:600px) {
    .thoughtful-caption {
        width:45%;
        font-size:30px;
        bottom:30px;
        left:5%;
    }    
}

.large-number {
    text-align:center;
    padding:0;
    font-size:220px;
    line-height:190px;
    font-weight:bold;
    color:white;
}
.large-number-title {
    text-align:center;
    padding:0;
    font-size:90px;
    line-height:95px;
    font-weight:bold;
    color:white;
    margin-top:20px;
    margin-bottom:15px;
}
.large-number-under {
    text-align:center;
    padding:0;
    font-size:38px;
    line-height:32px;
    font-weight:bold;
    color:rgba(255,255,255,.7);
    margin-bottom:25px;
}
.blue-paragraph {
    font-size: 23px;
    line-height: 110%;
    font-weight:bold;
    padding: 15px;
    color: #1c7091;   
}
.flex-center {
    display:flex;
    justify-content: center;
    align-items: center;
}
.flex-columns {
    flex-direction:column;
}

@media (max-width:700px) {
    .home-container-mid .left {
        float:none;
        width:100%;
    }
    .home-container-mid .left img {
        min-width:100%;
    }
    .home-container-mid .right {
        float:none;
        width:auto;
    }
}

@media (max-width:500px) {
    .home-container-mid .left img {
        margin-left:-40px;;
    }
}    
