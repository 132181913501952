@import '../../node_modules/react-modal-video/css/modal-video.min.css';

h1 {
  color: #9ccc42;
  font-size: 50px;
}

h2 {
  color: #666666;
  font-size: 45px;
  font-weight: normal;
}

h3 {
  color: #666666;
  font-size: 35px;
  font-weight: normal;
  line-height: 50px;
}

h4 {
  color: #9ccc42;
  font-size: 30px;
  margin: 0;
  padding: 25px 0 5px 0;
}

h5 {
  color: #666666;
  font-size: 27px;
  line-height: 33px;
  margin: 0;
  padding: 25px 0 5px 0;
  font-weight: normal;
}
p {
  line-height: 150%;
}
.para {
  margin: 0;
  padding: 3px 0 10px 0;
}
.para-large {
  font-size: 20px;
  line-height: 28px;
}

.align-center {
  text-align: center;
}

.main {
  margin: 15px auto;
  background: #ffffff;
  position: relative;
}

.main-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px 15px;
  position: relative;
}

.main-inner-large {
  max-width: 1400px;
  margin: 0 auto;
  padding: 5px 15px;
  position: relative;
}

.lesser-hero {
  margin: 70px auto;
}

.strapline {
  font-size: 30px;
  line-height: 38px;
  color: #777777;
  text-align: center;
}
.percentage-text {
  margin-bottom: 0;
  font-size: 60px;
}
.large-bold-text {
  font-weight: bold;
  font-size: 50px;
}
.large-padding-top-bottom {
  padding: 50px 0;
}
.large-padding-top {
  padding: 50px 0 25px 0;
}
.clear-fix {
  clear: both;
}
.grey-block {
  background: #f0f0ed;
}
.dark-grey-block {
  background: #777777;
}
.white-block {
  background: #ffffff;
}
.green-block {
  background: #9ccc42;
}
.decorative-border-grey {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.decorative-border-grey img {
  padding: 0;
  margin: 30px 0 0 0;
  width: 100%;
  display: block;
  min-width: 800px;
}

.white-button {
  padding: 15px 35px;
  max-width: 200px;
  background-color: white;
  border-radius: 50px;
  display: block;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
}

.align-center {
  text-align: center;
}

.underline-white {
  width: 50px;
  height: 4px;
  background: white;
  margin: 0 auto;
}

.large-grey-text {
  color: #777777;
  font-size: 60px;
  line-height: 65px;
  margin: 80px 0;
  text-align: center;
}

.medium-light-grey-text {
  color: #999;
  font-size: 40px;
  line-height: 55px;
  margin: 30px 0;
  text-align: center;
}

.large-grey-text-small-margin {
  color: #777777;
  font-size: 60px;
  line-height: 65px;
  margin: 20px 0;
  text-align: center;
}
.large-grey-text-margin-top {
  color: #777777;
  font-size: 60px;
  line-height: 65px;
  margin: 100px 0 40px 0;
  text-align: center;
}

.margin-base {
  margin-bottom: 100px;
}

.round-badge {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: table;
  bottom: 0;
  -webkit-transition: box-shadow 0.2s linear, bottom 0.2s;
  transition: box-shadow 0.2s linear, bottom 0.2s;
  cursor: pointer;
}

.round-badge:hover {
  position: absolute;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.3);
  bottom: 4px;
}

.round-badge-text {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 25px;
  line-height: 30px;
  text-decoration: none;
}

.other-pages-h1 {
  text-align: center;
}

.margin-top-50 {
  margin-top: 50px;
}
.margin-top-40 {
  margin-top: 40px;
}
.padding-30 {
  padding: 30px;
}

.other-pages-image-container {
  margin: 30px auto;
  max-width: 1200px;
}

.left-text-outer {
  padding: 40px;
  float: left;
  width: 38%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left-text-inner {
  font-size: 22px;
  line-height: 27px;
}

.right-image {
  float: right;
  width: 60%;
  box-sizing: border-box;
}

.middle-two-column {
  margin: 40px auto;
  max-width: 850px;
}

.middle-two-column-left {
  float: left;
  width: 42%;
}

.middle-two-column-right {
  float: right;
  width: 42%;
}
.block-image {
  display: block;
}

.links-outer {
  padding: 15px 0;
}
.links-row {
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
}

.padding-20 {
  padding: 20px;
}

.padding-5-20 {
  padding: 5px 20px;
}

.padding-20-20-5-20 {
  padding: 20px 20px 5px 20px;
}
.indent-left-small {
  padding-left:60px;
}
.medium-font {
  font-size: 22px;
  line-height: 30px;
  color: #555555;
}
.privacy-policy-table {
  border-collapse: collapse;
}
.privacy-policy-table td {
  border:1px solid black;
  padding:10px;

}
.privacy-button {
  padding-left:20px; padding-right:20px;
}
.underline-text {
  text-decoration:underline;
}
@media(max-width:600px) {
  .privacy-policy-table td {
    font-size:16px;
  }
}
.large-font {
  font-size: 27px;
  line-height: 37px;
  color: #555555;
  padding-top:15px;
}

.bold-warning {
  font-weight: bold;
  font-size: 110%;
}

.padding-20-lr {
  padding: 0 20px;
}

.para a {
  color: #149cb2;
  font-weight: bold;
}
a {
  color: #149cb2;
  font-weight: bold;
}

.small-italic-block {
  font-style: italic;
  font-size: 15px;
  line-height: 18px;
  padding-top: 20px;
}

.links-inner {
  float: left;
  width: 23%;
  box-sizing: border-box;
  padding: 15px 30px;
  margin: 1%;
  background: #eeeeee;
  display: block;
  color: black;
  text-decoration: none;
}

.links-inner-vacancies {
  float: left;
  width: 28%;
  box-sizing: border-box;
  padding: 15px 30px;
  margin: 1% 1.5%;
  border:1px solid #ccc;
  display: block;
  color: black;
  text-decoration: none;
  border-radius:6px;
}

.links-inner-vacancies:hover {
}

.vacancies-link {
  font-size:18px;
}

.links-inner:hover {
  background: #dddddd;
}

.link-company {
  display: block;
  margin: 5px 0;
  color: #0ca78d;
  font-size: 23px;
  text-decoration: none;
  cursor: pointer;
}

.vacancies-title {
  font-size:22px;
  text-align:center;
  font-weight:700;
}

.vacancies-info {
  font-size:16px;
  font-weight:400;
}

.trustees-outer {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0 30px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.trustees-inner {
  width: 20%;
  box-sizing: border-box;
  margin: 0 auto;
}

.trustees-image {
  text-align:center;
}
.trustees-image img {
  max-width: 70%;
  width: 70%;
}
.trustees-title {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  min-height: 40px;
  color: #77ba00;
}
.trustees-bio {
  padding: 5px;
  color: #777;
}
.button-outer {
  text-align: center;
  font-size: 20px;
}
.green-button {
  display: inline-block;
  margin: 0 10px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  padding: 12px 15px 9px 15px;
  font-size: 20px;
  cursor: pointer;
  background: #4ba700;
  border-bottom: 3px solid #336f03;
  font-weight: bold;
}
.green-button:hover {
  background: #336f03;
}
.image-container-row {
  display: flex;
  justify-content: space-evenly;
}
.image-container-row img {
  width: 30%;
}
.flex-row-container {
  display: flex;
  flex-direction: row;
  padding: 30px 0;
}
.flex-row-container img {
  width: 46%;
  display: block;
  margin: 0 2%;
}
.medium-ul {
  font-size: 22px;
  line-height: 135%;
  color: #555;
  list-style: none;
}
.medium-ul li {
  margin: 0.4em 0;
}
.medium-ul li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #9ccc42; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.bold-font {
  font-weight:bold;
}

@keyframes fadeIn {
  0% {opacity:0; transform:translate(0,20px); }
  50% {opacity:0;}
  100% {opacity:1; transform:translate(0,0);}
}

.fade-in {
  animation:fadeIn 1s ease-in-out;
}

@keyframes fadeInImage {
  0% {opacity:0; }
  100% {opacity:1;}
}

.fade-in-image {
  animation:fadeInImage .5s ease-in forwards;
}

@media (max-width: 700px) {
  .flex-row-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .flex-row-container img {
    width: 80%;
    display: block;
    margin: 2% 0;
  }
}
@media (max-width: 800px) {
  .trustees-outer {
    flex-wrap: wrap;
  }
  .trustees-inner {
    width: 40%;
    box-sizing: border-box;
    margin: 0 auto;
  }
  .links-inner {
    width: 45%;
  }
  .links-inner-vacancies {
    width: 45%;
  }
}

@media (max-width: 550px) {
  .links-inner {
    width: 100%;
  }
  .links-inner-vacancies {
    width: 100%;
  }
  .image-container-row {
    flex-direction: column;
  }
  .image-container-row img {
    width: 100%;
    display: inline-block;
    margin: 5px 0;
  }
}

@media (max-width: 900px) {
  .left-text-outer {
    padding: 40px;
    float: none;
    width: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-text-inner {
    font-size: 22px;
    line-height: 30px;
  }

  .right-image {
    float: none;
    width: auto;
    box-sizing: border-box;
  }

  .middle-two-column {
    margin: 40px auto;
    max-width: 850px;
  }

  .middle-two-column-left {
    float: none;
    width: auto;
  }

  .middle-two-column-right {
    float: none;
    width: auto;
  }
  .block-image {
    display: block;
  }
  .flex {
    display: flex;
    flex-direction: column;
  }
}

.pdf-block {
  width: 25%;
  float: left;
  padding: 20px 0;
}
.pdf-block a {
  color: black;
  font-size: 17px;
}

.video-placer {
  max-width: 900px;
  margin: 25px auto;
  display: block;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 600px) {
  h5 {
    color: #666666;
    font-size: 21px;
    line-height: 27px;
    margin: 0;
    padding: 25px 0 5px 0;
    font-weight: normal;
  }

  .round-badge {
    width: 100px;
    height: 100px;
  }

  .round-badge-text {
    font-size: 20px;
    line-height: 24px;
  }
  .pdf-block {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .round-badge {
    width: 90px;
    height: 90px;
  }

  .round-badge-text {
    font-size: 20px;
    line-height: 24px;
  }
}

.absolute-image-stretch-left-right {
  position: absolute;
  top: 0;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.max-width-380 {
  width: 380px;
  max-width: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.medium-large-font {
  font-size: 30px;
  line-height: 120%;
}

@media (max-width: 600px) {
  .main {
    margin: 5px auto;
  }

  .main-inner {
    padding: 2px 15px;
  }
  .main-inner-large {
    padding: 2px 15px;
  }
}
.full-width-outer-contact {
  margin:20px 0;
  padding:40px 0;
}
.full-width-contact {
  margin:0 0;
  padding:5px 0;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
}

.two-third-right-col {
  width: 60%;
  float: right;
}

.one-third-left-col {
  width: 32%;
  float: left;
  box-sizing: border-box;
}
.one-third-mid-col {
  width: 29%;
  margin-left: 4%;
  float: left;
}
.one-third-right-col {
  width: 29%;
  float: right;
}

.imageContainer {
  position: relative;
}

.imagePlacer {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
}

.imageMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/media/images/imageMask.png');
  background-size: cover;
}

@media (max-width: 900px) {
  .two-third-right-col {
    width: auto;
    float: none;
    margin-top: 30px;
  }

  .one-third-left-col {
    width: auto;
    float: none;
  }
  .one-third-mid-col {
    margin-top: 20px;
    margin-bottom: 20px;
    width: auto;
    float: none;
    margin-left: 0%;
  }
  .one-third-right-col {
    width: auto;
    float: none;
  }
}

.amazon-badge, .breathe-badge {
  max-height:220px;
  margin:20px 20px 20px 20px;
}

.max-width-100 {
  max-width:100%;
}

.base-logo {
  max-height:120px;
  margin: 20px 10px;
  padding: 0 15px;
}
.border-right-base-logo {
  border-right:1px solid #ccc;
}

.breathe-badge-footer {
  max-height:120px;
  margin:0;
}
.footer-logos {
  max-height:95px;
}
.homeless-link-logo {
  max-width:400px;
  width:100%;
  padding:0 15px;
}
.crla-logo {
  max-width:250px;
  width:100%;
}

.address-footer {
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  margin-bottom:30px;
}

.address-footer-align-top {
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:top;
  margin-bottom:30px;
}

.address-left {
  margin-right:30px;
  padding-right:30px;
  text-align:left;
  border-right:1px solid rgba(255,255,255,.5);
  padding-top:10px;
  padding-bottom:10px;
}

.address-center {
  margin-right:30px;
  padding-right:30px;
  text-align:left;
  padding-top:10px;
  padding-bottom:10px;
}

@media (max-width:650px) {
  .address-left {
    margin-right:0;
    padding-right:0;
    text-align:left;
    border-right:none;
    text-align:center;
    margin-bottom:20px;
  }
  .address-center {
    margin-right:0;
    padding-right:0;
    text-align:left;
    border-right:none;
    text-align:center;
    margin-bottom:20px;
  }
  .address-footer {
    display:block;
  }
  .address-footer-align-top {
    display:block;
  }
}

.black-text-link {
  color:black !important;
  font-weight:normal !important;
  text-decoration:none;
}

.white-text-link {
  color:white !important;
  font-weight:normal !important;
  text-decoration:none;
}

.give-as-you-live{max-width:300px; max-width:300px;}