.footer {
  margin: 0 auto;
  background: #9ccc42;
  color: white;
  text-align: center;
  padding: 45px 0;
}
.footer .normal {
  font-size: 25px;
  line-height: 34px;
}
.footer .large {
  font-size: 50px;
  line-height: 80px;
}
.footer .small {
  font-size: 15px;
  line-height: 22px;
}

.footer .social-media {
  margin: 10px auto;
}
.footer .social-media a {
  color:white;
  margin:0 20px;
  font-weight:400;
}


@media (max-width: 700px) {
  .footer .large {
    font-size: 30px;
    line-height: 60px;
  }
}
