
.script-font {
  font-family: "Handy", "Covered By Your Grace", cursive;
}




.normal-text {
  font-weight:normal;
}

.bold-text {
  font-weight:bold;
}

.medium-text {
  font-size:25px;
  line-height:120%;
}

.no-padding {
  padding:0;
}

.no-margin {
  margin:0;
}

.rounded-corners {
  border-radius:15px;
}

.align-center {
  text-align:center;
}