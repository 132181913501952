.green-text {
    color: #9ccc42;
}

.blue-text {
    color: #149cb2;
}

.dark-blue-text {
    color: #1c7091;
}

.light-grey {
    color:#f0f0ed;
}

.white-text {
    color:#ffffff;
}

.green-back {
    background:#9ccc42;
}

.light-blue-back {
    background:#27bba7;
}

.dark-blue-back {
    background:#1c7091;
}

.purple-back {
    background:#4b2582;
}

.dark-green-back {
    background:#008674;
}
.blue-block {
    background: #149cb2;
}