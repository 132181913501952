.whats-on {
    margin:0 auto;
    max-width:900px;
    
}
.whats-on .two-thirds {
    background:#ffffff;
    float:none;
    /*width:63%; */
    width:90%;
    font-size:19px;
    margin: 0 auto;
}
@media (max-width:700px) {
    .whats-on .two-thirds {
        float:none;
        width:auto;
    }
}

.whats-on .two-thirds .title {
    font-size:35px;
    line-height:130%;
    text-align:center;
    padding:30px 0 15px 0;
    font-weight:bold;
}
.whats-on .two-thirds .col {
    float:left;
    width:47%;
    margin:0 2% 0 0;
}
.whats-on .two-thirds .col ul {
    list-style: none;
    padding-left:20px;
}
.whats-on .two-thirds .col ul {
    line-height:170%;
}
.whats-on .one-third {
    background:#ffffff;
    float:right;
    width:30%;
}
.whats-on .two-thirds,.one-third .inner {
    padding:20px;
}
.whats-on .two-thirds .inner img {
    max-width:100%;
}
.whats-on .one-third .twitter-header {
    display:table;
    text-align: center;
    
    background:rgb(69, 179, 243);
    min-height:170px;
    width:100%;
}
.whats-on .one-third .twitter-header .inner {
    display:table-cell;
    text-align: center;
    vertical-align:middle;
    
}
.whats-on .one-third .twitter-header img {
    max-width:70px;
}
@media (max-width:700px) {
    .whats-on .one-third {
        width:100%;
        display:flex;
        justify-content:center;
        flex-direction:column;
    }
    .whats-on .one-third .twitter-header img {
        max-width:auto;
    }
}
@media (max-width:500px) {
    .whats-on .two-thirds .col {
        float:none;
        width:auto;
        margin:0 0;
        text-align:center;
    }
   .whats-on .one-third .col {
        float:none;
        width:auto;
        margin:0 0;
        text-align:center;
    }
    
}