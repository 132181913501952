.client-testimonial-container {
    position: relative;
    margin-top:30px;
    margin-right:30px;
    margin-bottom:30px;
    padding:30px;
    background: rgba(169,198,6,.8);
    float:right;
    min-width:300px;
    width:35%;
}

.client-testimonial-header {
    color:#ffffff;
    font-size:25px;
    line-height:28px;
    font-weight:bold;
}

.client-testimonial-header-large {
    color:#ffffff;
    font-size:35px;
    line-height:28px;
    font-weight:bold;
    padding-bottom:25px;
}

.client-testimonial-text {
    color:#000000;
    font-size:16px;
    line-height:20px;
    font-weight:normal;
}

.client-testimonial-button {
    display:block;
    background:white;
    padding:10px 20px 8px 20px;
    border-radius:40px;
    font-size:18px;
    text-align:center;
    margin:2px auto;
    cursor:pointer;
    text-decoration:none;
    color:black;
}

.wheelchair-background-color {
    background-color:#ad9a94;
}

@media (max-width:600px) {
    .client-testimonial-container {
        position: relative;
        margin:0;
        padding:30px;
        background: rgba(169,198,6,1);
        float:none;
        width:auto;
        min-width:auto;
    }
    .wheelchair-desktop-only {
        display:none;
    }
}